













































import {
  Component, Vue,
} from 'vue-property-decorator';
import GoalContributionSuccessViewModel
  from '@/vue-app/view-models/components/goals-dashboard/contribute-goals/goal-contribution-success-view-model';

@Component({
  name: 'GoalContributionSuccess',
})
export default class GoalContributionSuccess extends Vue {
  goal_contribution_success_view_model = Vue.observable(
    new GoalContributionSuccessViewModel(this),
  );
}

