import Vue from 'vue';
import TYPES from '@/types';

// Application
import LinkedGoalStateManagerService
  from '@/modules/flagship/investor-goal/linked-investor-goal/application/services/linked-goal-state-manager-service';

// Domain
import LinkedGoalState
  from '@/modules/flagship/investor-goal/linked-investor-goal/domain/state/linked-goal-state';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import Inject from '@/modules/shared/domain/di/inject';

export default class GoalContributionSuccessViewModel {
  @Inject(TYPES.LINKED_GOAL_STATE_MANAGER_SERVICE)
  private readonly linked_goal_state_manager_service!:
    LinkedGoalStateManagerService;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly view: Vue;

  i18n_namespace = 'components.goals-dashboard.contribute-goals.goal-contribution-success';

  linked_goal_state: LinkedGoalState;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  public constructor(view: Vue, linked_goal_state?: LinkedGoalState) {
    this.view = view;
    this.linked_goal_state = linked_goal_state || this.linked_goal_state_manager_service
      .getLinkedGoalState();
  }

  accreditation_date = '';

  setAccreditationDate(accreditation_date: string) {
    this.accreditation_date = accreditation_date;
  }

  continue() {
    this.view.$emit('endProcess');
  }
}
